import { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import { Skeleton, Stack, TextField } from "@mui/material";
import { createLocation, getTerminalbylatlong,updatePlanningTerminalOfDriver } from "../../services/planningBoardService";
import { BaseConfig } from "../../baseConfig";
import { NotificationContainer, NotificationManager } from "react-notifications";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import Config from './../../Config.json'
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import { getAllDrivers } from '../../services/driverService';
import { Autocomplete } from "@mui/lab";
import { getAllPlanningProfiles } from "../../services/userService";
import { toast } from "react-toastify";
import { ContextData } from "../appsession";
import CircularProgress from '@mui/material/CircularProgress';

const DriverHeaderInfo = (props) => {
  const { driverById } = props;
  const [modalShow, setModalShow] = useState(false);
  const [planningTerminalModal,setPlanningTerminalModal] = useState(false);
  const [planningTerminalConfirmModal,setPlanningTerminalConfirmModal] = useState(false);
  const [latt, setlatdata] = useState('');
  const [long, setlongdata] = useState('');
  const [modalShowParking, setModalShowParking] = useState(false);
  const [driver, setDriver] = useState(driverById)
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [selectedLocationLatitude, setSelectedLocationLatitude] = useState("");
  const [selectedLocationLongitude, setSelectedLocationLongitude] = useState("");
  const [locationsDataFromMap, setLocationsDataFromMap] = useState([]);
  const [searchLocationData, setSearchLocationData] = useState("");
  const [selectedDistance, setSelectedDistance] = useState(5);
  const miles = [10, 15, 20];
  const [terminalData, setTerminalData] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState('');
  const [originalTerminal, setOrginalTerminal] = useState('');
  const [planningTerminalcnfButton, setplanningTerminalcnfButton] = useState(false);
  const [planningParkingModal, setPlanningParkingModal] = useState(false);
  

  useEffect(() => {
    if (driver && driver?.planningDriverTerminal?.terminal_fullName) {
      setOrginalTerminal(driver?.planningDriverTerminal?.terminal_fullName)
    }
  }, [])

  useEffect(() => {
    (async () => {
      if (planningParkingModal && modalShowParking ) {
        TrimbleMaps.APIKey = BaseConfig.Mapkey;
        const map = new TrimbleMaps.Map({
          container: 'map', // container id
          style: TrimbleMaps.Common.Style.TRANSPORTATION, //hosted style id

          center: new TrimbleMaps.LngLat(
            selectedLocationLongitude,
            selectedLocationLatitude
          ), // starting position
          zoom: 9 // starting zoom
        });
        map.addControl(new TrimbleMaps.NavigationControl());

        const scale = new TrimbleMaps.ScaleControl({
          maxWidth: 80,
          unit: "imperial",
        });
        const marker = new TrimbleMaps.Marker({
          draggable: true
        }).setLngLat([
         selectedLocationLongitude,
          selectedLocationLatitude
        ]).addTo(map);

        map.addControl(scale);
        // Listen to the dragend event of the marker
        marker.on("dragend", async function (e) {
          // Extract the lngLat object from the marker in the event
          const lngLat = e.target.getLngLat();
          setSelectedLocationLongitude(lngLat.lng.toFixed(4));
          setSelectedLocationLatitude(lngLat.lat.toFixed(4));
          let payload = {
            longitude: lngLat.lng.toFixed(4),
            latitude: lngLat.lat.toFixed(4),
            searchtext: searchLocationData,
            distance: selectedDistance
          };
          try {
            setIsLocationLoading(true);
            const latlanglocation = await getTerminalbylatlong(payload);
            if (latlanglocation) {
              setIsLocationLoading(false);
              setLocationsDataFromMap(latlanglocation);
            }
          } catch (error) {
            NotificationManager.error("There is a problem in retrieving locations data.");
          }
        });

        if (selectedLocationLongitude && selectedLocationLatitude) {
          let payload = {
            longitude: selectedLocationLongitude,
            latitude: selectedLocationLatitude,
            searchtext: searchLocationData,
            distance: selectedDistance,
          };

          try {
            setIsLocationLoading(true);
            const allLocationsBasedOnLatLong = await getTerminalbylatlong(payload);
            if (allLocationsBasedOnLatLong) {
              setIsLocationLoading(false);
              setLocationsDataFromMap(allLocationsBasedOnLatLong);
            }
          } catch (error) {
            toast.error("There is a problem in retrieving the location data.");
          }
        }
        setPlanningParkingModal(false);
      }
      else if (modalShowParking && !planningParkingModal) {
        TrimbleMaps.APIKey = BaseConfig.Mapkey;

        const map = new TrimbleMaps.Map({
          container: 'map', // container id
          style: TrimbleMaps.Common.Style.TRANSPORTATION, //hosted style id

          center: new TrimbleMaps.LngLat(
            driver?.bpaDriverParking?.location?.longitude ?? driver?.terminal?.longitude,
            driver?.bpaDriverParking?.location?.latitude ?? driver?.terminal?.latitude
          ), // starting position
          zoom: 9 // starting zoom
        });
        map.addControl(new TrimbleMaps.NavigationControl());

        const scale = new TrimbleMaps.ScaleControl({
          maxWidth: 80,
          unit: "imperial",
        });
        const marker = new TrimbleMaps.Marker({
          draggable: true
        }).setLngLat([
          driver?.bpaDriverParking?.location?.longitude ?? driver?.terminal?.longitude,
          driver?.bpaDriverParking?.location?.latitude ?? driver?.terminal?.latitude
        ]).addTo(map);

        map.addControl(scale);
        // Listen to the dragend event of the marker
        marker.on("dragend", async function (e) {
          // Extract the lngLat object from the marker in the event
          const lngLat = e.target.getLngLat();
          setSelectedLocationLongitude(lngLat.lng.toFixed(4));
          setSelectedLocationLatitude(lngLat.lat.toFixed(4));
          let payload = {
            longitude: lngLat.lng.toFixed(4),
            latitude: lngLat.lat.toFixed(4),
            searchtext: searchLocationData,
            distance: selectedDistance
          };
          try {
            setIsLocationLoading(true);
            const latlanglocation = await getTerminalbylatlong(payload);
            if (latlanglocation) {
              setIsLocationLoading(false);
              setLocationsDataFromMap(latlanglocation);
            }
          } catch (error) {
            NotificationManager.error("There is a problem in retrieving locations data.");
          }
        });

        if (selectedLocationLongitude && selectedLocationLatitude) {
          let payload = {
            longitude: selectedLocationLongitude,
            latitude: selectedLocationLatitude,
            searchtext: searchLocationData,
            distance: selectedDistance,
          };

          try {
            setIsLocationLoading(true);
            const allLocationsBasedOnLatLong = await getTerminalbylatlong(payload);
            if (allLocationsBasedOnLatLong) {
              setIsLocationLoading(false);
              setLocationsDataFromMap(allLocationsBasedOnLatLong);
            }
          } catch (error) {
            toast.error("There is a problem in retrieving the location data.");
          }
        }
      }
     
    })();

  }, [modalShowParking]);

  const handleSearchInput = (e) => {
    setSearchLocationData(e.target.value);
  };

  const searchbylatlong = async (e, searchText) => {
    e.preventDefault();
    try {
      if (searchText && selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchText,
          distance: selectedDistance
        };
        const latlanglocation = await getTerminalbylatlong(payload);

        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      } else if (selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: selectedDistance
        };
        const latlanglocation = await getTerminalbylatlong(payload);
        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      }

    } catch (error) {
      NotificationManager.error("There is a problem in retrieving the location data.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchbylatlong();
    }
  };

  const filterLocationByDist = async (e) => {
    const value = Number(e.target.value);
    setSelectedDistance(value);
    try {
      if (selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: value
        };

        const latlanglocation = await getTerminalbylatlong(payload);

        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      }
    } catch (error) {
      NotificationManager.error("There is a problem in retrieving the location data.")
    }
  };

  const openLocationModalParking = async (e) => {
    setLocationsDataFromMap([]);
    setSelectedLocationLatitude(driver?.bpaDriverParking?.location?.latitude ?? driver?.terminal?.latitude);
    setSelectedLocationLongitude(driver?.bpaDriverParking?.location?.longitude ?? driver?.terminal?.longitude);
    setModalShowParking(true);
    setPlanningTerminalConfirmModal(false)
  };

  function getLatLongByTerminalName(terminalData, selectedTerminal) {
    const terminal = terminalData.filter(t => t.name === selectedTerminal);
    if (terminal.length>0) {
        return {
            latitude: terminal[0]?.terminal?.latitude,
            longitude: terminal[0]?.terminal?.longitude
        };
    } else {
        return null;
    }
}

const openPlanningLocationModalParking = async (e) => {
  setPlanningParkingModal(true);
  setLocationsDataFromMap([]);
  const result = getLatLongByTerminalName(terminalData, selectedTerminal);
  const latitude = result ? result.latitude : null;
  const longitude = result ? result.longitude : null;
  setSelectedLocationLatitude(latitude);
  setSelectedLocationLongitude(longitude);
  setModalShowParking(true);
  setPlanningTerminalConfirmModal(false);
};

  const handleLocationSave = async (
    location) => {
    console.log("location tony: ", location)

    let payload = {
      "driver_id": driver?.driver_id,
      "location_id": location?.code
    };

    try {
      const response = await createLocation(payload);
      if (response) {
        setModalShowParking(false);
        setLocationsDataFromMap([]);
        setSelectedDistance(5);
        setSearchLocationData("");
        setSelectedLocationLatitude("");
        setSelectedLocationLongitude("");

        NotificationManager.success(
          `Location updated for ${driver?.driver_id}`,
          "Success",
          3000);

        let filterData = {
          terminalId: [driver.terminal_id],
        };
        try {
          const driver_res_data = await getAllDrivers(filterData);
          let driver_id_data = driver_res_data.filter((e) => e.driver_id == driver.driver_id);
          setDriver(driver_id_data[0])
        }
        catch (err) {
          console.log("error in getting all driver", err)
        }
      }
    }
    catch (e) {
      NotificationManager.error(
        "There has some error while changing the location",
        "Error",
        3000
      );
    }
  }

  const openLocationModal = (e) => {
    setModalShow(true);
  };

  useEffect(() => {
    setlatdata(driver?.terminal?.latitude)
    setlongdata(driver?.terminal?.longitude)
  }, [driver?.terminal?.latitude, driver?.terminal?.longitude]);

  useEffect(() => {
    if (long || latt) {
      TrimbleMaps.APIKey = `${Config.dev.trimbleMapKey}`;
      const map = new TrimbleMaps.Map({
        container: 'map', // container id
        style: TrimbleMaps.Common.Style.TRANSPORTATION, //hosted style id

        center: new TrimbleMaps.LngLat(long, latt), // starting position
        zoom: 12 // starting zoom

      });

      const marker = new TrimbleMaps.Marker({
        draggable: true
      }).setLngLat([long, latt]).addTo(map);
      map.addControl(new TrimbleMaps.NavigationControl());

      const scale = new TrimbleMaps.ScaleControl({
        maxWidth: 80,
        unit: 'imperial'
      });
      map.addControl(scale);
    }
  }, [modalShow]);

  const parkingLocationStates = function () {
    setModalShowParking(false);
    setIsLocationLoading(false);
    setSelectedDistance(5);
    setSearchLocationData("");
    setSelectedLocationLatitude("");
    setSelectedLocationLongitude("");
  };

  const openPlanningTerminalModal = async (e) => {
    try {
      setSelectedTerminal(originalTerminal)
      const data = await getAllPlanningProfiles();
      setTerminalData(data);
      setPlanningTerminalModal(true);
    } catch (error) {
      console.error('Error fetching terminal data:', error);
      toast.error("Error fetching terminal data");
    }
  }
  const handleTerminalChange = (value) => {
    setSelectedTerminal(value);
  };


  const handleUpdatePlanningTerminal = async(e) => {
    setplanningTerminalcnfButton(true)
    try{
      if(selectedTerminal){
        let payload={
          driver_id:driver.driver_id,
          planning_terminal:selectedTerminal?.split("-")?.[0]?.trim()
        }
       const res=await updatePlanningTerminalOfDriver(payload);
       setPlanningTerminalConfirmModal(true)
       setPlanningTerminalModal(false) 
       setplanningTerminalcnfButton(false);
       setOrginalTerminal(selectedTerminal);
       toast.success("Planning terminal updated sucessfully.")
      }
    }
    catch(err){
      setplanningTerminalcnfButton(false)
      console.log("Error in updating planning terminal",err);
      toast.error("Something went wrong, please try again later!");
    }
  }

  return (
    <>
      {/* <ToastContainer position='top-right' autoClose={2000} theme='colored' /> */}
      <div className="tab">
        <div className="row blue_header_twosidespace" style={{ justifyContent: "space-between" }}>        
          <div className="col-md-4">
            <p className="profile_left_text_trailers">
              {driver.driver_id} - {driver.driver_name}
            </p>
          </div>
          <Tooltip title={driver.is_active ? "Active" : "Inactive"}>
              <div
                className={driver.is_active ? "online_sign" : "offline_sign"}
              ></div>
            </Tooltip>
          </div>
          <div className="row blue_header_twosidespace" >
          <div className="col-md-4">
            <div className="title_for_trailer">
            <span>
              Terminal: &nbsp;
              <text className="text_color_white">
                {`${driver?.terminal?.terminal_id} - ${driver?.terminal?.name}`
                  ?? "No Data"}
              </text>
              </span>
              <span>
                Driver Type: &nbsp;
                <text className="text_color_white">
                  {driver.equipment_type_id
                    ? driver.equipment_type_id
                    : "No Data"}
                </text>
              </span>
              <span>
                Email: &nbsp;
                <text className="text_color_white">
                  {driver.email ? driver.email : "No Data"}
                </text>
              </span>

              <span>
                Cell: &nbsp;
                <text className="text_color_white">
                  {driver.cell_phone ? driver.cell_phone : "No Data"}
                </text>
              </span>

              <span>
                Birth Date: &nbsp;
                <text className="text_color_white">
                  {driver.birth_date === null
                    ? "No Data"
                    : DateTime.fromISO(driver.birth_date)
                      .toFormat("MM/dd/yyyy")
                      .toString()}
                </text>
              </span>
            </div>
          </div>

          <div className="col-md-5">
          <div style={{ display: "flex"}}>
              <div className="profile_top_left_text1">
                Planning Terminal:&nbsp;
              </div>
             
              <text className="text_color_white">
                {originalTerminal? originalTerminal : "No Data"}
            </text>&nbsp;
            <div onClick={openPlanningTerminalModal}><CreateSharpIcon sx={{ color: "white", cursor: "pointer" }} /></div>
               </div>
            <div style={{ display: "flex" }}>
              <div className="profile_top_left_text1">
                Parking Location: &nbsp;
              </div>
              <div onClick={openLocationModalParking}><CreateSharpIcon sx={{ color: "white", cursor: "pointer" }} /></div>
            </div>
            <text className="text_color_white">
              {driver?.bpaDriverParking?.location_id ? `${driver?.bpaDriverParking?.location_id} - ${driver?.bpaDriverParking?.location?.location_name ?? ""}` : `${driver?.terminal?.terminal_id} - ${driver?.terminal?.name}` ?? "No Data"}
            </text>
            <p className="text_color_white">
              {`${driver?.bpaDriverParking?.location?.address1 ?? ""}`}&nbsp;
            </p>
            <p className="text_color_white">
              {`${driver?.bpaDriverParking?.location?.address2 ?? ""}, ${driver?.bpaDriverParking?.location?.city ?? ""}, ${driver?.bpaDriverParking?.location?.zip ?? ""}`}&nbsp;
            </p>
          </div>

          <div className="col-md-3">
            <div className="title_for_trailer">
              <span>
             
              <div className="profile_top_left_text1">
              Region: &nbsp;
              <text className="text_color_white">
                {driver.region ? driver.region : "No Data"}
              </text>
            </div>

           
            
              </span>
              <span>
                Address: &nbsp;
                <text className="text_color_white">
                  {driver.address ?? ""}
                  <br />
                  {driver.city || driver.state || driver.zip ?
                    `${driver.city}, ${driver.state}, ${driver.zip}` : "No Data"
                  }
                </text>
              </span>
            </div>
            <p className="text_color_white">
              {/* {latt ? latt : "No Data"}
            ,&nbsp;
            {long ? long : "No Data"} */}
              {driver?.terminal?.latitude ?? "No Data"}
              ,&nbsp;
              {driver?.terminal?.longitude ?? "No Data"}
              <span className="icon_for_location">
                <i
                  className="fa fa-map-marker"
                  aria-hidden="true"
                  onClick={(e) => openLocationModal(e)}
                ></i>
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Modal for Planning Terminal data change */}
      {planningTerminalModal ? (<Modal show={planningTerminalModal} onHide={() => { setPlanningTerminalModal(false), setSelectedTerminal('') }}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>
              Planning Terminal</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
          <div className="meterial_autocomplete">
            <label htmlFor="typeHeadAddStatus">Choose a Planning Terminal*</label>
            <div className='mt_10'>
              <Autocomplete
                id="combo-box-demo"
                options={terminalData?.map(terminal => terminal.name)}
                onChange={(e, option) => handleTerminalChange(option)}
                value={selectedTerminal}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select a Planning Terminal"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setPlanningTerminalModal(false),setSelectedTerminal('')}}>Close</Button>
          {
            planningTerminalcnfButton ? <CircularProgress /> :
          <Button variant="primary" onClick={handleUpdatePlanningTerminal}>Confirm</Button>
          }
        </Modal.Footer>
      </Modal>) : null}


      {/* Modal for planning terminal change confirmation */}
      {planningTerminalConfirmModal ? (<Modal show={planningTerminalConfirmModal} onHide={() => setPlanningTerminalConfirmModal(false)}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
          Do you want to change the Parking Location?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {setPlanningTerminalConfirmModal(false)}}>No</Button>
          <Button variant="primary" onClick={openPlanningLocationModalParking}>Yes</Button>
        </Modal.Footer>
      </Modal>) : null}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            CURRENT LOCATION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="map" style={{ height: "400px", width: "100%", }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL CODE */}
      <Modal
        show={modalShowParking}
        onHide={() => {parkingLocationStates()}}
        size="lg"
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='text-bold'>
            <p className='tank_mapview_header'>Change Default Location</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='split_view_container'>
            <div className='split_view_left'>
              <div className='search_section'>
                <div className='search_field_tankwash'>
                  <form class='example'>
                    <input
                      type='text'
                      placeholder='Search..'
                      name='search2'
                      onChange={(e) => handleSearchInput(e)}
                      onKeyUp={handleKeyPress}
                    />
                    <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                      <i class='fa fa-search'></i>
                    </button>
                  </form>
                </div>
                <div className='split_popup_dropdown'>
                  <form>
                    <select
                      name='cars'
                      id='cars'
                      value={selectedDistance}
                      onChange={(e) => filterLocationByDist(e)}
                    >
                      <option value='5'>
                        5
                      </option>
                      {miles.map((mile, k) => (
                        <option key={k}>{mile}</option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <p className='nearby_text'>
                Nearby Locations:{locationsDataFromMap.length}
              </p>
              <div className='location_section_wrapper'>
                {isLocationLoading ? (
                  <Stack spacing={1}>
                    <div className='loader_skeleton'>
                      <Skeleton variant='rectangular' />
                    </div>
                  </Stack>
                ) : (
                  <></>
                )}
                {locationsDataFromMap.map((location, index) => (
                  <div className='location_section'>
                    <div className='location_section_left'>
                      <div className='location_name'>
                        {location.code}-{location.location_name}
                        <br />
                        {location.address1}
                        <br />
                        {location.city},{location.state},{location.zip}
                      </div>
                      <div className='location_name'>
                        Distance:{location.distance} miles
                      </div>
                      <div className='location_section_right'></div>
                      <button
                        type='button'
                        class='btn-blue_schedule'
                        onClick={(i) =>
                          handleLocationSave(
                            location
                          )
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='split_view_right_map'>
              <div id='map' className="split_view_right_map__map" style={{ height: "700px", width: "100%" }}>

              </div>

            </div>
            <div className="split_view_right_map__guide">
              <p>To change the default parking location, drag the red pin and drop where you would like the set the location. Then save a location from the list of nearby locations</p>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button className="btn_blue btn-blue" >Change</Button> */}
          <Button onClick={() => {parkingLocationStates()}
          }>Close</Button>
        </Modal.Footer>
      </Modal>
      {/* Confirmation Modal for transfer */}
      <NotificationContainer />
    </>
  );
};

export default DriverHeaderInfo;
